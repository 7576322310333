import React, { useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";

const ApiCall = () => {
  const { keycloak, initialized } = useKeycloak();
  const [data, setData] = useState(null); // State for API data
  const [loading, setLoading] = useState(true); // State for loading
  const [error, setError] = useState(null); // State for error handling

  useEffect(() => {
    if (initialized && keycloak.authenticated) {
      // Get the token
      const token = keycloak.token;

      // Call the API
      fetch("https://api.vendefacil.com.mx/productos", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Accepts": "text/plain",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          "X-Auth-Token": `Bearer ${token}`,
        },
      })
        .then((response) => {
          console.log(response)
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.text(); // Parse JSON response
        })
        .then((data) => {
          setData(data); // Update state with data
          setLoading(false); // Set loading to false
        })
        .catch((err) => {
          console.log(err)
          setError(err.message); // Update error state
          setLoading(false); // Set loading to false
        });
    }
  }, [keycloak, initialized]); // Run when Keycloak is initialized or changes

  // Render loading, error, or data
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <h1>API Result:</h1>
      <p>{JSON.stringify(data, null, 2)}</p>
    </div>
  );
};

export default ApiCall;