import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
  url: process.env.REACT_APP_IDP_URL,
  realm: process.env.REACT_APP_REALM,
  clientId: process.env.REACT_APP_CLIENT_ID
});

const keycloakInitOptions = {
  onLoad: 'check-sso',
  redirectUri: process.env.REACT_APP_REDIRECT_URI,
  checkLoginIframe: false,
  pkceMethod: "S256"
};

export {keycloak, keycloakInitOptions}
